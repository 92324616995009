import cx from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  useState, useEffect, useContext, useRef,
} from 'react';
import { useMutation } from '@apollo/client';
import { useGoogleLogin } from '@react-oauth/google';

import WhiteBox from 'components/elems/whitebox';
import ComponentLoader from 'components/elems/comLoader';
import PageTitle from 'components/elems/typography/pageTitle';

import { LOGIN_USER, LOGIN_GOOGLE_USER } from 'lib/api';
import { setToken, ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'utils/api';

import { useToastOnEffect } from 'utils/customHooks';
import UserContext from 'utils/context';
import NickName from './name';

import GoogleIcon from 'public/icons/google.svg';

import styles from './styles.module.css';

const SignIn = ({ className, callBack, showTitle = false }) => {
  const router = useRouter();
  const container = useRef();
  const { updateUser } = useContext(UserContext);
  const [emailValue, setEmailValue] = useState('');
  const [tokenValue, setTokenValue] = useState('');
  const [googleCode, setGoogleCode] = useState('');
  const [showNick, setShowNick] = useState(false);

  const [requestTokenQL, { loading, error, data: requestTokenResp }] = useMutation(LOGIN_USER,
    { variables: { email: emailValue } });
  const [loginQL, { loading: signLoading, error: signError, data: loginResp }] = useMutation(LOGIN_USER,
    { variables: { email: emailValue, token: tokenValue } }, { errorPolicy: 'all' });
  const [loginGoogleQL, { loading: googleLoading, error: googleError, data: googleResp }] = useMutation(LOGIN_GOOGLE_USER,
    { variables: { code: googleCode } });

  const requestToken = (ev) => {
    ev.preventDefault();
    requestTokenQL();
  };
  const login = (ev) => {
    ev.preventDefault();
    loginQL();
  };


  const googleLogin = useGoogleLogin({
    onSuccess: response => {
      setGoogleCode(response.code);
      loginGoogleQL();
    },
    flow: 'auth-code',
  });

  useToastOnEffect('Can not send token. Request token again.', [error], 'error');
  useToastOnEffect('Log in failed. Request new token.', [signError], 'error');
  useToastOnEffect('Log in with Google failed. Try again or sign in with email.', [googleError], 'error');
  useToastOnEffect('We have sent a token to your email!', [requestTokenResp], 'success');

  const finalFunc = () => {
    updateUser().then(() => {
      if (callBack) {
        callBack();
      } else {
        router.push('/bets/create');
      }
    });
  };

  useEffect(() => {
    if (loginResp?.login?.tokens || googleResp?.login?.tokens) {
      const tokens = loginResp?.login?.tokens || googleResp?.login?.tokens;

      setToken(ACCESS_TOKEN_KEY, tokens.access.value, tokens.access.expires);
      setToken(REFRESH_TOKEN_KEY, tokens.refresh.value, tokens.refresh.expires);
      finalFunc();
    } else if (loginResp?.login?.newUserToken || googleResp?.login?.newUserToken) {
      setShowNick(true);
    } else {
      setTokenValue('');
    }
  }, [loginResp, googleResp]);

  useEffect(() => {
    if (container.current) {
      container.current.focus();
    }
  }, [container]);

  return (
    <div ref={container} tabIndex={-1} className={cx(styles.container, 'flex-center', className)}>
      {showTitle && (
        <PageTitle force noLine className="mb-12">
          LOG IN
        </PageTitle>
      )}
      <WhiteBox className={cx(styles.box, 'overflow-hidden')}>
        <ComponentLoader className={styles.block} loading={signLoading}>
          <ComponentLoader loading={googleLoading}>
            <button className={cx(styles.googleButton, { [styles.hidden]: showNick }, 'px-3 py-3 border border-gray-sdr rounded-lg global-button')} onClick={googleLogin}>
              <GoogleIcon />
              Sign in with Google
            </button>
          </ComponentLoader>
          <hr className={cx(styles.divider, { hidden: showNick })} />
          <form
            action="#"
            onSubmit={requestToken}
            className={cx({ hidden: showNick })}
          >
            <input
              id="email"
              type="email"
              required
              value={emailValue}
              placeholder="Email"
              className={cx(styles.input, 'text-black px-3 py-3 border border-gray-sdr rounded-lg global-input')}
              onChange={(e) => setEmailValue(e.target.value)}
            />
            <button
              type="submit"
              className={cx('global-button mt-6 bg-primary-main text-white font-bold py-2 px-6 rounded-lg global-button', {
                'cursor-not-allowed opacity-50 pointer-events-none': loading,
              })}
            >
              Request token
            </button>
          </form>
          <form
            action="#"
            onSubmit={login}
            className={cx('mt-10', { hidden: showNick })}
          >
            <ComponentLoader loading={loading}>
              <input
                id="token"
                type="text"
                required
                value={tokenValue}
                placeholder="Token"
                className={cx(styles.input, 'text-black px-3 py-3 border border-gray-sdr rounded-lg global-input', {
                  'cursor-not-allowed pointer-events-none': !requestTokenResp,
                  [styles.disabled]: !requestTokenResp,
                })}
                onChange={(e) => setTokenValue(e.target.value)}
              />
            </ComponentLoader>
            <p className="mt-4 mb-4 text-xs">
              <span className="text-red-main">*</span>
              {' '}
              By registering, you agree with our
              {' '}
              <Link href="/terms">
                <a className="link">
                  General Terms & Conditions
                </a>
              </Link>
              {' '}
              and
              {' '}
              <Link href="/cookies">
                <a className="link">
                  Cookie Policy
                </a>
              </Link>
              .
            </p>
            <button
              type="submit"
              className={cx(
                'mt-4 bg-primary-main text-white font-bold py-2 px-12 rounded-lg global-button', {
                  'opacity-50 cursor-not-allowed pointer-events-none': !requestTokenResp || signLoading,
                },
              )}
            >
              Sign In
            </button>
          </form>
          <NickName
            tokenValue={loginResp?.login?.newUserToken || googleResp?.login?.newUserToken}
            show={showNick}
            finalFunc={finalFunc}
          />
        </ComponentLoader>
      </WhiteBox>
    </div>
  );
};

export default SignIn;
