import cx from 'classnames';

import styles from './whitebox.module.css';

const WhiteBox = ({
  elem: Elem = 'div', className, children, onClick = null, ...rest
}) => (
  <Elem onClick={onClick} className={cx(className, styles.whiteBox, 'rounded-lg my-shadow')} {...rest}>
    { children }
  </Elem>
);

export default WhiteBox;
