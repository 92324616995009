import cx from 'classnames';

import styles from './styles.module.css';

const ComponentLoader = ({ className, children, loading = false }) => (
  <div className={cx(className, {
    [styles.loader]: loading,
  })}
  >
    {loading && (
      <div className={cx(styles.spinner, 'flex-center')}>
        <div className={cx(styles.item, styles.bounce1)} />
        <div className={cx(styles.item, styles.bounce2)} />
        <div className={cx(styles.item, styles.bounce3)} />
      </div>
    )}
    { children }
  </div>
);

export default ComponentLoader;
