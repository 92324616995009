import cx from 'classnames';
import { useState, useEffect, useMemo } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useToastOnEffect } from 'utils/customHooks';
import ComponentLoader from 'components/elems/comLoader';
import debounce from 'lodash.debounce';

import { CREAT_USER, CHECK_NICKNAME } from 'lib/api';

import { setToken, ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'utils/api';

import Warning from 'public/icons/warning.svg';
import Check from 'public/icons/check_circle.svg';

import styles from './styles.module.css';

const NickName = ({ show, tokenValue, finalFunc }) => {
  const [nickname, setNickname] = useState('');
  const [toched, setToched] = useState(false);
  const [createUser, { loading, error, data }] = useMutation(CREAT_USER,
    { variables: { nickname, token: tokenValue } }, { errorPolicy: 'all' });
  const [checkNickname, { loading: checkLoading, error: checkError, data: checkData }] = useLazyQuery(CHECK_NICKNAME,
    { variables: { nickname } });
  const create = (ev) => {
    ev.preventDefault();
    if (checkData?.checkNickname?.available) {
      createUser();
    }
  };
  const onChange = useMemo(() => debounce((e) => {
    setNickname(e.target.value.trim());
    setToched(true);
  }, 300), []);

  useEffect(() => {
    if (data?.createUser?.tokens) {
      const tokens = data?.createUser?.tokens;
      setToken(ACCESS_TOKEN_KEY, tokens.access.value, tokens.access.expires);
      setToken(REFRESH_TOKEN_KEY, tokens.refresh.value, tokens.refresh.expires);
    }
    if (data?.createUser?.user?.nickname) {
      finalFunc();
    }
  }, [data]);

  useEffect(() => {
    if (nickname.length > 2) {
      checkNickname();
    }
  }, [nickname]);

  useToastOnEffect('Something went wrong 😔. Please, try again.', [error, checkError], 'error');

  if (!show) {
    return null;
  }

  const suggestions = checkData?.checkNickname?.suggestions;
  const suggestionsLen = suggestions?.length;
  const nickLen = nickname.length < 3;

  return (
    <form
      action="#"
      onSubmit={create}
    >
      <ComponentLoader className="relative" loading={checkLoading}>
        <input
          id="nickname"
          type="text"
          required
          size="45"
          minLength="3"
          maxLength="45"
          placeholder="Nickname"
          className={cx(styles.input, 'text-black pl-3 pr-8 py-3 border border-gray-sdr rounded-lg global-input', {
            [styles.inputSuc]: checkData?.checkNickname?.available,
          })}
          onChange={onChange}
        />
        <span className={cx(styles.icon, { [styles.iconSuc]: checkData?.checkNickname?.available })}>
          {suggestionsLen > 0 && <Warning className="w-6 h-6" />}
          {checkData?.checkNickname?.available && <Check className="w-6 h-6" /> }
        </span>
      </ComponentLoader>
      <p className={cx(styles.suggestions, 'mt-2 mb-2 text-xs', {
        [styles.open]: toched && (suggestionsLen > 0 || nickLen),
      })}
      >
        {nickLen ? 'Nickname cannot be empty or less than 3 letters.' : (
          <>
            Nickname already in use. Suggestions:
            {' '}
            { suggestions?.join(', ') }
          </>
        )}
      </p>
      <p className="mt-4 mb-2 text-xs">
        <span className="text-red-main">*</span>
        {' '}
        Nickname cannot be empty or less than 3 letters.
      </p>
      <p className="mb-4 text-xs">
        <span className="text-red-main">*</span>
        {' '}
        Nickname containing insults on any grounds, advertising or obscene words will be removed according to the rules of PoolUp.
      </p>
      <button
        type="submit"
        className={cx(
          'mt-6 bg-primary-main border border-primary-main text-white font-bold py-2 px-4 rounded', {
            'opacity-50 cursor-not-allowed pointer-events-none': loading || !checkData?.checkNickname?.available,
          },
        )}
      >
        Create account
      </button>
    </form>
  );
};

export default NickName;
