import cx from 'classnames';

import styles from './pageTitle.module.css';

const PageTitle = ({
  children, className, force, noLine = true,
}) => (
  <h2 className={cx('text-2xl text-center mb-12', styles.title, className, {
    [styles.force]: force,
    [styles.lines]: !noLine,
  })}
  >
    { children }
  </h2>
);

export default PageTitle;
